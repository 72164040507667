<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>User </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateUser">
            <CRow>
              <CCol sm="6">
              <CInput
                v-model="username"
                label="Username"
                placeholder="Input username"
              />
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="nickname"
                label="Nama Lengkap"
                placeholder="Input nama lengkap"
              />  
              </CCol> 
              <CCol sm="6">
              <CInput
                v-model="email"
                label="Email"
                placeholder="Input email"
              />
              </CCol>
              <!-- <CCol sm="6">
              <CInput
                v-model="pass"
                type="password"
                label="Password"
                placeholder="Input password"
              />  
              </CCol>  -->
              <CCol sm="6">
                <div class="form-group">
                  <label> Level</label>
                  <select v-model="level" class="form-control">
                    <option value="admin">Admin</option>
                    <option value="operator">Operator</option>
                    <option value="user">User</option>
                  </select>
                </div>
              </CCol>      

            </CRow>           
              <div class="form-actions">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/user">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      :show.sync="modalError"
      
    >
      <b>Error</b>! Data User gagal diinput.
    </CModal>
   
  </div>    
  
</template>

<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      username : "",
      nickname : "",
      email : "",
      // pass : "",
      // level : "",
      selected: [], // Must be an array reference!
      show: true,
      modalError: false,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  // this.loadData();
  this.showUser();
  },
  methods: {
    // loadData: function(event){
      
    //   let username =window.btoa(this.$route.params.id_user);
    //   console.log(username);

    //   axios.get(process.env.VUE_APP_BASE_URL+"api/public/user/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu")
    //     .then((response) => {
    //           this.pohon=response.data;
    //     })
    // },

    updateUser: function(event){
      // Simple POST request with a JSON body using axios
      const user = { 
                          email: this.email, 
                          nickname: this.nickname,
                          username: this.username,
                          level: this.level,
                          };
      // alert(JSON.stringify(pekerjaan));
      axios.put(process.env.VUE_APP_BASE_URL+"user/"+this.$route.params.id_user, user)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/user');
            }
            else{
              this.modalError = true;
            }
            
        })
    },
    showUser: function(event){
      // Simple POST request with a JSON body using axios
      const user = { 
                          username: this.username, 
                          nickname: this.nickname,
                          email: this.email 
                          };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"user/"+this.$route.params.id_user)
        .then((response) => {
         
              this.username=response.data.username;
              this.nickname=response.data.nickname;
              this.email=response.data.email;
              this.level=response.data.level;
                          
        })
    }
  }
}
</script>